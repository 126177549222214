<script>
import EpLayout from '@/layouts/Default.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'maintenance',
  components: { EpLayout },

  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    ...mapState('auth', {
      cgu: state => state.session.askForNewCgu,
      isMIPIH: state => state.isMIPIH,
    }),
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
    }),
  },
};
</script>

<template>
  <ep-layout class="el-body" v-if="user">
  <div class="body body-maintenance">
    <div class="full_page">
      <div class="header__logo">
        <div class="ef-navbar__logo" ><img src="../../../static/img/logo.png" alt="Logo eDocPerso"></div>
      </div>
      <div class="mainDiv">
        <div class="maintenance_picture"><img src="../../../static/img/maintenance_orange.svg" alt="Image maintenance"></div>
        <div class="midDiv">
          <span class="error">503</span>
          <span class="explanation">{{ $t('collector.maintenance.title')}}</span>
          <div class="informationDiv">
            <p>{{ $t('collector.maintenance.text.part1') }}</p>
            <p>{{ $t('collector.maintenance.text.part2') }}</p>
            <p>{{ $t('collector.maintenance.text.part3') }}</p>
            <p>{{ $t('collector.maintenance.text.part4') }}</p>
            <p>{{ $t('collector.maintenance.text.part5') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </ep-layout>
</template>

<style scoped>

.el-body {
  overflow-y: hidden;
}

.ef-navbar__logo img {
  width: 190px;
}

.body-maintenance * {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  overflow-y: hidden;
  margin: unset;
  box-sizing: unset;
}

.body-maintenance *::-webkit-scrollbar {
  display: none;
}

.body-maintenance p {
  color: #050505;
  padding-bottom: 1.5rem;
  font-size: 1rem;
}

header {
  display: flex;
  margin: auto;
  padding: 1rem;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
}

.error {
  align-self: stretch;
  color: #A3A3A3;
  /* Heading/H1/Bold */
  font-family: Lato;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
}

.explanation {
  align-self: stretch;
  color: #050505;
  /* Heading/H1/Bold */
  font-family: "Lato";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 36.4px */
  padding-top: 1rem;
}

.mainDiv {
  display: flex;
  align-items: center;
  background: #FFF;
}

.informationDiv {
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #050505;
  /* Body/Large/Regular */
  font-family: "Lato";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  flex-shrink: 0;
}

.informationDiv__bold {
  font-weight: 700;
}

.midDiv {
  display: flex;
  flex-direction: column;
}

.header__logo {
  display: flex;
  height: 4rem;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
}

/*--Tablet ++ Mobile--*/
@media screen and (min-width: 320px) and (max-width: 1024px) {

  .el-body {
    overflow-y: auto;
  }

  .error {
    text-align: center;
    overflow-y: unset;
  }

  .explanation {
    overflow-y: unset;
  }

  .mainDiv {
    overflow-y: hidden;
    max-width: 65rem;
    height: 70.8125rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.875rem;
    padding: 1.88rem 2.56rem 0;
  }

  .midDiv {
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    width: auto;
  }

  .header__logo {
    height: 4rem;
    padding: 1.5rem;
    gap: 1.5rem;
    flex-shrink: 0;
  }

  .maintenance_picture {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
  }
}

/*--Mobile--*/
@media screen and (min-width: 320px) and (max-width: 768px) {
  .explanation {
    text-align: center;
  }
}

/*--Desktop ++ Big screen--*/
@media screen and (min-width: 1025px) {

  .mainDiv {
    padding-bottom: 5.5rem;
    max-width: 90rem;
    max-height: 44rem;
    width: fit-content;
    margin: auto;
    vertical-align: middle;
  }

  .informationDiv {
    height: 33rem;
  }

  .midDiv {
    max-width: 43.188rem;
    align-items: flex-start;
    gap: 1rem;
    flex-shrink: 0;
    padding-top: 6rem;
    margin-right: 6.75rem;
  }

  .header__logo {
    padding: 1.5rem;
  }

  .maintenance_picture {
    order: 2;
    line-height: 10rem;
  }
}

/*--Big-screen--*/
@media screen and (min-width: 1600px) {

  .midDiv {
    padding-top: 12rem;
  }

  .maintenance_picture {
    padding-top: 5rem;
    line-height: 10rem;
  }
}


</style>
